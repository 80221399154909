import * as React from 'react'

import Layout from '../layouts/layout'
// import sty from '../layouts/styles/index'
import { ContentBox } from 'src/components/Main/ContentBox'
import useImage from 'src/hooks/useImage'


const AboutPage = () => {

  const { family } = useImage()

  return (
    <Layout seo={{ title: 'Über Uns' }}>
      <ContentBox
        src={false}
        title='Über Uns'
        subtitle='Ein Familienbetrieb seit 1986'
        text={<>
          Aus einer ehemaligen Schreinerei und Zimmerei im kleinen Kanton Appenzell Innerrhoden haben wir uns auf Isolierarbeiten im Einblasverfahren, kurz &laquo;Einblasen&raquo;, spezialisiert.
          Über die letzten 30 Jahre konnten wir unser Know-how von einblasbaren Materialien aufbauen, festigen und ständig ausbauen.
        </>}
        button={false}
      />
      <ContentBox
        src={family}
        title=''
        subtitle=''
        text={<>
          Dank unserer langjährigen Erfahrung im Einblasen haben wir den Aufbau unserer Maschinen auf diesen Einsatz massgeschneidert und entwickeln diese auch stets weiter.
          So können wir unseren Kunden die gewohnte Qualität garantieren.
          <br/><br/>
          Anfänglich noch als &laquo;Streule Einblasisolationen&raquo; bekannt, haben wir uns für einen Namenswechsel entschieden.
          Dies im Zeichen der neuen Frische, welches durch die jüngere Generation nun angetrieben wird.
        </>}
        button={false}
      />
    </Layout>
  )
}

export default AboutPage
